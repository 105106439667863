import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Component.css"; // Import your CSS file

const QuestionSelectionPage = ({
  selectedYearId,
  selectedSubjectId,
  setSelectedYearId,
  setSelectedSubjectId,
  setSelectedChapterId,
  setSelectedTopicId,
  setSelectedExamId,
}) => {
  console.log(selectedYearId, selectedSubjectId);
  const [topicName, setTopicName] = useState("");
  const [years, setYears] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [chapters, setChapters] = useState([]);
  const [selectedChapter, setSelectedChapter] = useState("");
  const [topics, setTopics] = useState([]);
  const [exams, setExams] = useState([]);
  const [selectedExam, setSelectedExam] = useState("");

  const baseUrl = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}`;

  useEffect(() => {
    const fetchYears = async () => {
      try {
        const response = await axios.get(`${baseUrl}/years`);
        console.log(response.data);
        setYears(response.data);
      } catch (error) {
        console.error("Error fetching years:", error);
      }
    };

    fetchYears();
  }, [baseUrl]);

  useEffect(() => {
    const fetchSubjects = async () => {
      if (selectedYearId) {
        try {
          const response = await axios.get(
            `${baseUrl}/subjects?yearId=${selectedYearId}`
          );
          setSubjects(response.data);
        } catch (error) {
          console.error("Error fetching subjects:", error);
        }
      }
    };

    fetchSubjects();
  }, [selectedYearId, baseUrl]);

  useEffect(() => {
    const fetchChapters = async () => {
      if (selectedSubjectId) {
        try {
          const response = await axios.get(
            `${baseUrl}/chapters?subjectId=${selectedSubjectId}`
          );
          setChapters(response.data);
        } catch (error) {
          console.error("Error fetching chapters:", error);
        }
      }
    };

    fetchChapters();
  }, [selectedSubjectId, baseUrl]);

  useEffect(() => {
    const fetchTopics = async () => {
      if (selectedChapter) {
        try {
          const response = await axios.get(
            `${baseUrl}/topics?chapterId=${selectedChapter}`
          );
          setTopics(response.data);
        } catch (error) {
          console.error("Error fetching topics:", error);
        }
      }
    };

    fetchTopics();
  }, [selectedChapter, baseUrl]);

  // Fetch exam names
  useEffect(() => {
    const fetchExams = async () => {
      try {
        const response = await axios.get(`${baseUrl}/exams`);
        setExams(response.data);
      } catch (error) {
        console.error("Error fetching exams:", error);
      }
    };

    fetchExams();
  }, [baseUrl]);

  useEffect(() => {
    setSelectedYearId(selectedYearId);
  }, [selectedYearId, setSelectedYearId]);

  useEffect(() => {
    setSelectedSubjectId(selectedSubjectId);
  }, [selectedSubjectId, setSelectedSubjectId]);

  useEffect(() => {
    setSelectedChapterId(selectedChapter);
  }, [selectedChapter, setSelectedChapterId]);

  useEffect(() => {
    setSelectedTopicId(topicName);
  }, [topicName, setSelectedTopicId]);

  useEffect(() => {
    setSelectedExamId(selectedExam);
  }, [selectedExam, setSelectedExamId]);

  return (
    <div className="container p-0">
      <h2>Select filter</h2>
      <div className="form-container">
        <div className="form-group">
          <label htmlFor="yearSelect" className="form-label">
            Year:
          </label>
          <select
            id="yearSelect"
            className="form-select"
            value={selectedYearId}
            disabled
          >
            <option value="">Select Year</option>
            {years.map((year) => (
              <option key={year.year_id} value={year.year_id}>
                {year.year_name}
              </option>
            ))}
          </select>
        </div>
        {selectedYearId && (
          <div className="form-group">
            <label htmlFor="subjectSelect" className="form-label">
              Subject:
            </label>
            <select
              id="subjectSelect"
              className="form-select"
              value={selectedSubjectId}
              disabled
            >
              <option value="">Select Subject</option>
              {subjects.map((subject) => (
                <option key={subject.subject_id} value={subject.subject_id}>
                  {subject.subject_name}
                </option>
              ))}
            </select>
          </div>
        )}
        {selectedSubjectId && (
          <div className="form-group">
            <label htmlFor="chapterSelect" className="form-label">
              Chapter:
            </label>
            <select
              id="chapterSelect"
              className="form-select"
              value={selectedChapter}
              onChange={(e) => setSelectedChapter(e.target.value)}
              required
            >
              <option value="">Select Chapter</option>
              {chapters.map((chapter) => (
                <option key={chapter.chapter_id} value={chapter.chapter_id}>
                  {chapter.chapter_name}
                </option>
              ))}
            </select>
          </div>
        )}
        {selectedChapter && (
          <div className="form-group">
            <label htmlFor="topicSelect" className="form-label">
              Topic:
            </label>
            <select
              id="topicSelect"
              className="form-select"
              value={topicName}
              onChange={(e) => setTopicName(e.target.value)}
              required
            >
              <option value="">Select Topic</option>
              {topics.map((topic) => (
                <option key={topic.topic_id} value={topic.topic_id}>
                  {topic.topic_name}
                </option>
              ))}
            </select>
          </div>
        )}
        <div className="form-group">
          <label htmlFor="examSelect" className="form-label">
            Exam:
          </label>
          <select
            id="examSelect"
            className="form-select"
            value={selectedExam}
            onChange={(e) => setSelectedExam(e.target.value)}
            required
          >
            <option value="">Select Exam</option>
            {exams.map((exam) => (
              <option key={exam.exam_id} value={exam.exam_id}>
                {exam.exam_name}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};

export default QuestionSelectionPage;
