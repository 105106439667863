import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import Question from './Question';
import Sidebar from './Sidebar';
import Header from './Header';
import './Main.css';

function Main() {
    const { templateId } = useParams();
    const examTemplateId = templateId;
    const userId = localStorage.getItem("userId");
    const navigate = useNavigate();
    const location = useLocation();
    const [questions, setQuestions] = useState([]);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [responses, setResponses] = useState([]);
    const [visitedQuestions, setVisitedQuestions] = useState([]);
    const [markedForReview, setMarkedForReview] = useState([]);
    const [markedForReviewAndAnswered, setMarkedForReviewAndAnswered] = useState([]);
    const [questionTimes, setQuestionTimes] = useState({});
    const [currentStartTime, setCurrentStartTime] = useState(null);

    useEffect(() => {
        console.log("im calling")
        fetch(`http://localhost:1020/exam-template-questions/${templateId}`)
            .then(response => response.json())
            .then(data => {
                const allQuestions = data.flatMap(subject => subject.questions);
                setQuestions(allQuestions);
            });

        if (location.state) {
            setCurrentQuestionIndex(location.state.currentQuestionIndex);
            setResponses(location.state.responses);
            setVisitedQuestions(location.state.visitedQuestions);
            setMarkedForReview(location.state.markedForReview);
            setMarkedForReviewAndAnswered(location.state.markedForReviewAndAnswered);
            setQuestionTimes(location.state.questionTimes || {});
        }

        setCurrentStartTime(Date.now());
    }, [templateId, location.state]);

    const updateQuestionTime = () => {
        if (currentStartTime) {
            const timeSpent = Date.now() - currentStartTime;
            setQuestionTimes((prevTimes) => {
                const currentQuestionId = questions[currentQuestionIndex]?.id;
                return {
                    ...prevTimes,
                    [currentQuestionId]: (prevTimes[currentQuestionId] || 0) + timeSpent
                };
            });
        }
    };

    const goToQuestion = (index) => {
        updateQuestionTime();
        setCurrentStartTime(Date.now());
        setCurrentQuestionIndex(index);
        setVisitedQuestions((prevVisited) => {
            const currentQuestion = questions[index];
            if (!prevVisited.includes(currentQuestion.id)) {
                return [...prevVisited, currentQuestion.id];
            }
            return prevVisited;
        });
    };

    const nextQuestion = (questionId, selectedOption) => {
        updateQuestionTime();
        setCurrentStartTime(Date.now());
        setResponses((prevResponses) => {
            const existingResponseIndex = prevResponses.findIndex(response => response.questionId === questionId);
            if (existingResponseIndex >= 0) {
                const newResponses = [...prevResponses];
                newResponses[existingResponseIndex].selectedOption = selectedOption;
                return newResponses;
            }
            return [...prevResponses, { questionId, selectedOption }];
        });
        if (currentQuestionIndex < questions.length - 1) {
            setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
        }
    };

    const previousQuestion = () => {
        updateQuestionTime();
        setCurrentStartTime(Date.now());
        if (currentQuestionIndex > 0) {
            setCurrentQuestionIndex((prevIndex) => prevIndex - 1);
        }
    };

    const clearSelection = (questionId) => {
        setResponses((prevResponses) =>
            prevResponses.filter(response => response.questionId !== questionId)
        );
    };

    const markForReview = () => {
        setMarkedForReview((prevMarked) => {
            const currentQuestion = questions[currentQuestionIndex];
            if (!prevMarked.includes(currentQuestion.id)) {
                return [...prevMarked, currentQuestion.id];
            }
            return prevMarked;
        });
    };

    const markForReviewAndNext = (questionId, selectedOption) => {
        setMarkedForReviewAndAnswered((prevMarked) => {
            if (!prevMarked.includes(questionId)) {
                return [...prevMarked, questionId];
            }
            return prevMarked;
        });
        if (selectedOption) {
            setResponses((prevResponses) => {
                const existingResponseIndex = prevResponses.findIndex(response => response.questionId === questionId);
                if (existingResponseIndex >= 0) {
                    const newResponses = [...prevResponses];
                    newResponses[existingResponseIndex].selectedOption = selectedOption;
                    return newResponses;
                }
                return [...prevResponses, { questionId, selectedOption }];
            });
        }
        if (currentQuestionIndex < questions.length - 1) {
            setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
        }
    };

    const submitExam = () => {
        updateQuestionTime();
        const payload = { userId, examTemplateId, responses, questionTimes };
        const summaryData = {
            totalQuestions: questions.length,
            answered: responses.length,
            notAnswered: questions.length - responses.length,
            markedForReview: markedForReview.length,
            markedForReviewAndAnswered: markedForReviewAndAnswered.length,
            notVisited: questions.length - visitedQuestions.length,
        };

        const previousState = {
            currentQuestionIndex,
            responses,
            visitedQuestions,
            markedForReview,
            markedForReviewAndAnswered,
            questionTimes
        };

        navigate('/exam-summary', { state: { summaryData, payload, previousState } });
    };

    return (
        <div className="main">
            <Header />
            <div className="main-content">
                <Question
                    question={questions[currentQuestionIndex]}
                    nextQuestion={(questionId, selectedOption) => nextQuestion(questionId, selectedOption)}
                    previousQuestion={previousQuestion}
                    clearSelection={() => clearSelection(questions[currentQuestionIndex].id)}
                    markForReview={markForReview}
                    markForReviewAndNext={(questionId, selectedOption) => markForReviewAndNext(questionId, selectedOption)}
                    submitExam={submitExam}
                    setResponses={setResponses}
                    responses={responses}
                />
                <Sidebar
                    questions={questions}
                    answeredQuestions={responses.map(response => response.questionId)}
                    visitedQuestions={visitedQuestions}
                    markedForReview={markedForReview}
                    markedForReviewAndAnswered={markedForReviewAndAnswered}
                    goToQuestion={goToQuestion}
                    currentQuestionIndex={currentQuestionIndex}
                />
            </div>
        </div>
    );
}

export default Main;
