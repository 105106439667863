import React from 'react';
import './Sidebar.css';

const Sidebar = ({ questions, answeredQuestions, visitedQuestions, markedForReview, markedForReviewAndAnswered, goToQuestion, currentQuestionIndex }) => {
    return (
        <div className="sidebar">
            <div className="status">
                <div><span className="status-color not-visited"></span> {questions.length - visitedQuestions.length} Not Visited</div>
                <div><span className="status-color not-answered"></span> {visitedQuestions.length - answeredQuestions.length} Not Answered</div>
                <div className='ans'><span className="status-color answered"></span> {answeredQuestions.length} Answered</div>
                <div><span className="status-color marked-review"></span> {markedForReview.length} Marked for Review</div>
                <div><span className="status-color answered-marked-review"></span> {markedForReviewAndAnswered.length} Answered & Marked for Review</div>
                
            </div>
            <div className="question-list">
                {questions.map((question, index) => {
                    let className = 'question-number';

                    if (markedForReviewAndAnswered.includes(question.id)) {
                        className += ' marked-review-answered';
                    } else if (markedForReview.includes(question.id)) {
                        className += ' marked-review';
                    } else if (answeredQuestions.includes(question.id)) {
                        className += ' answered';
                    } else if (visitedQuestions.includes(question.id)) {
                        className += ' not-answered';
                    } else {
                        className += ' not-visited';
                    }

                    // Mark current question as red if it is not answered or marked
                    if (currentQuestionIndex === index && !answeredQuestions.includes(question.id) && !markedForReview.includes(question.id) && !markedForReviewAndAnswered.includes(question.id)) {
                        className += ' current-question';
                    }

                    return (
                        <div
                            key={index + 1}
                            className={className}
                            onClick={() => goToQuestion(index)}
                        >
                            {index + 1}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default Sidebar;
