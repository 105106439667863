import React from "react";
import { motion } from "framer-motion";

export const BouncyCardsFeatures = () => {
  return (
    <section className="BouncyCardsFeatures-section">
      <div className="BouncyCardsFeatures-top">
        <h2 className="BouncyCardsFeatures-top-title">
          Grow faster with our
          <span className="BouncyCardsFeatures-top-title-span"> all in one solution</span>
        </h2>
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          className="BouncyCardsFeatures-top-button"
        >
          Learn more
        </motion.button>
      </div>

      <div className="Bouncycards-1-col">
        <BounceCard className="Bouncecard Bouncycard-1">
          <CardTitle className="Bouncecard-card-title">Do a thing</CardTitle>
          <div className="Bouncycard-card Bouncycard-card-hover  Bouncy-violet">
            <span className="block text-center font-semibold text-indigo-50">
              FEATURE DEMO HERE
            </span>
          </div>
        </BounceCard>
        <BounceCard className="Bouncecard Bouncycard-2">
          <CardTitle>Do another thing</CardTitle>
          <div className="Bouncycard-card Bouncycard-card-hover bg-gradient-to-br from-amber-400 to-orange-400">
            <span className="block text-center font-semibold text-orange-50">
              FEATURE DEMO HERE
            </span>
          </div>
        </BounceCard>
      </div>

      <div className="Bouncycards-1-col reverse">
        <BounceCard className="Bouncecard Bouncycard-3">
          <CardTitle>And this too</CardTitle>
          <div className="Bouncycard-card Bouncycard-card-hover bg-gradient-to-br from-green-400 to-emerald-400 ">
            <span className="block text-center font-semibold text-emerald-50">
              FEATURE DEMO HERE
            </span>
          </div>
        </BounceCard>
        <BounceCard className="Bouncecard Bouncycard-4">
          <CardTitle>And finally this</CardTitle>
          <div className="Bouncycard-card Bouncycard-card-hover bg-gradient-to-br from-pink-400 to-red-400">
            <span className="block text-center font-semibold text-red-50">
              FEATURE DEMO HERE
            </span>
          </div>
        </BounceCard>
      </div>
    </section>
  );
};

const BounceCard = ({ className, children }) => {
  return (
    <motion.div
      whileHover={{ scale: 0.95, rotate: "-1deg" }}
      className={`Bouncecard group Bouncecard-group bg-slate-100 ${className}`}
    >
      {children}
    </motion.div>
  );
};

const CardTitle = ({ children }) => {
  return (
    <h3 className="Bouncecard-card-title">{children}</h3>
  );
};

export default BouncyCardsFeatures;