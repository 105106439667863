import React, { useState } from 'react';
import CountUp from 'react-countup';
import ScrollTrigger from 'react-scroll-trigger';
import './Counterup.css';


const CounterUpPage = () => {
    const [counterOn, setCounterOn ] = useState(false);
    return (
        <ScrollTrigger onEnter={() => setCounterOn(true)  }>

        <div className='counter-up'>
            <div className='counter-up-line'></div>
            <div className='counter-up-card'>

            <div>
            <h1 className='counter-up-text'>
            {counterOn && <CountUp start={0} end={100000} duration={3} delay={0} />}
              +
            </h1>
            <h3 className='counter-up-title'>Total Questions</h3>
            </div>


            <div>
            <h1 className='counter-up-text'>
            {counterOn && <CountUp start={0} end={100} duration={3} delay={2} />}
              +
            </h1>
            <h3 className='counter-up-title'>Total Chapters</h3>
            </div>


            <div>
            <h1 className='counter-up-text'>
            {counterOn && <CountUp start={0} end={300} duration={3} delay={2} />}
              +
            </h1>
            <h3 className='counter-up-title'>Total Exams</h3>
            </div>


            <div>
            <h1 className='counter-up-text'>
            {counterOn && <CountUp start={0} end={250} duration={3} delay={3} />}
              +
            </h1>
            <h3 className='counter-up-title'>Resources</h3>
            </div>

            
            </div>
        </div>
        </ScrollTrigger>
    );
};

export default CounterUpPage