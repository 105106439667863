import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import QuoteCarousel from "./landingpage/Quotes/QuoteCarousel";
import PopupQuote from "./landingpage/Quotes/PopupQuote";
import './UserDashboard.css';

const UserDashboard = () => {
  const [username, setUsername] = useState("");
  const userId = localStorage.getItem("userId");
  const [templates, setTemplates] = useState([]);
  const [filteredAvailableExams, setFilteredAvailableExams] = useState([]);
  const [filteredAttendedExams, setFilteredAttendedExams] = useState([]);
  const [availableFilters, setAvailableFilters] = useState({ type: "All", subject: "", day: "" });
  const [attendedFilters, setAttendedFilters] = useState({ type: "All", subject: "", day: "" });
  const [showPopupQuote, setShowPopupQuote] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    const storedUsername = localStorage.getItem("username");
    if (storedUsername) {
      setUsername(storedUsername);
      console.log("Stored Username:", storedUsername);
    }
  }, []);

  useEffect(() => {
    if (userId) {
      axios
        .get("http://localhost:1020/created-templates-list", {
          params: { userId: userId },
        })
        .then((response) => {
          setTemplates(response.data);
          setFilteredAvailableExams(response.data.filter(template => !template.attended));
          setFilteredAttendedExams(response.data.filter(template => template.attended));
        })
        .catch((error) => {
          console.error("Error fetching templates:", error);
        });
    } else {
      console.error("User ID is not available");
    }
    console.log("User ID:", userId);
  }, [userId]);

  const handleTemplateClick = (templateId) => {
    navigate(`/instruction-page/${templateId}`);
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate("/");
  };

  const filterExams = (templates, filters) => {
    let exams = templates;
    const { type, subject, day } = filters;

    if (type !== "All") {
      exams = exams.filter(template => template.examType === type);
    }

    if (subject) {
      exams = exams.filter(template => template.subject === subject);
    }

    if (day) {
      const daysMap = {
        "last 7days": 7,
        "last 15days": 15,
        "last 30days": 30,
      };
      const days = daysMap[day];
      const now = new Date();
      exams = exams.filter(template => {
        const uploadDate = new Date(template.uploadDate);
        const diffDays = Math.ceil((now - uploadDate) / (1000 * 60 * 60 * 24));
        return diffDays <= days;
      });
    }

    return exams;
  };

  const handleFilterChange = (column, filterType, value) => {
    const filters = column === "available" ? availableFilters : attendedFilters;
    const updatedFilters = { ...filters, [filterType]: value };

    if (column === "available") {
      setAvailableFilters(updatedFilters);
      setFilteredAvailableExams(filterExams(templates.filter(template => !template.attended), updatedFilters));
    } else {
      setAttendedFilters(updatedFilters);
      setFilteredAttendedExams(filterExams(templates.filter(template => template.attended), updatedFilters));
    }
  };

  return (
    <div id="main">
      {showPopupQuote && <PopupQuote onClose={() => setShowPopupQuote(false)} />}
      <header className="header-landing">
        <nav>
          <h1>MITHRA.</h1>
          <div className="nav-part2">
            <a href="/">HOME</a>
            <a href="/about">ABOUT US</a>
            <a href="/contact">CONTACT US</a>
            <a href="/help">NEED HELP?</a>
          </div>
          <button onClick={handleLogout}>Logout</button>
        </nav>
      </header>
      <div className="examhead">
        <h2>Welcome {username} to Mithra</h2>
        <h3>Exam Templates</h3>
      </div>

      <div className="exam-columns">
        <div className="exam-column">
          <div className="filter-container">
            <h3 className="available-exams-title">Available Exams</h3>
            <div className="filter-buttons">
              <button onClick={() => handleFilterChange("available", "type", "All")}>All</button>
              <button onClick={() => handleFilterChange("available", "type", "JEE")}>JEE</button>
              <button onClick={() => handleFilterChange("available", "type", "KCET")}>KCET</button>
              <button onClick={() => handleFilterChange("available", "type", "NEET")}>NEET</button>
            </div>
            <div className="filter-dropdowns">
              <select onChange={(e) => handleFilterChange("available", "subject", e.target.value)} value={availableFilters.subject}>
                <option value="">Subject</option>
                <option value="Physics">Physics</option>
                <option value="Chemistry">Chemistry</option>
                <option value="Maths">Maths</option>
                <option value="Biology">Biology</option>
              </select>
              <select onChange={(e) => handleFilterChange("available", "day", e.target.value)} value={availableFilters.day}>
                <option value="">Day</option>
                <option value="last 7days">Last 7 days</option>
                <option value="last 15days">Last 15 days</option>
                <option value="last 30days">Last 30 days</option>
              </select>
            </div>
          </div>
          <div className="exam-list">
            {filteredAvailableExams.length === 0 ? (
              <p>No exams available.</p>
            ) : (
              filteredAvailableExams.map((template) => (
                <div
                  className="card"
                  key={template.id}
                  onClick={() => handleTemplateClick(template.id)}
                >
                  <div className="card-content">
                    <img className="Examcard-image" src="https://via.placeholder.com/300x250" alt="" />
                    <h3>{template.name}</h3>
                    <h2>Exam Type: {template.examType}</h2>
                    <h2>Subject: {template.subject}</h2>
                    <h2>Time Limit: {template.timeLimit}</h2>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>

        <div className="exam-column">
          <div className="filter-container">
            <h3 className="attended-exams-title">Attended Exams</h3>
            <div className="filter-buttons">
              <button onClick={() => handleFilterChange("attended", "type", "All")}>All</button>
              <button onClick={() => handleFilterChange("attended", "type", "JEE")}>JEE</button>
              <button onClick={() => handleFilterChange("attended", "type", "KCET")}>KCET</button>
              <button onClick={() => handleFilterChange("attended", "type", "NEET")}>NEET</button>
            </div>
            <div className="filter-dropdowns">
              <select onChange={(e) => handleFilterChange("attended", "subject", e.target.value)} value={attendedFilters.subject}>
                <option value="">Subject</option>
                <option value="Physics">Physics</option>
                <option value="Chemistry">Chemistry</option>
                <option value="Maths">Maths</option>
                <option value="Biology">Biology</option>
              </select>
              <select onChange={(e) => handleFilterChange("attended", "day", e.target.value)} value={attendedFilters.day}>
                <option value="">Day</option>
                <option value="last 7days">Last 7 days</option>
                <option value="last 15days">Last 15 days</option>
                <option value="last 30days">Last 30 days</option>
              </select>
            </div>
          </div>
          <div className="exam-list">
            {filteredAttendedExams.length === 0 ? (
              <p>No exams attended.</p>
            ) : (
              filteredAttendedExams.map((template) => (
                <div
                  className="card attended"
                  key={template.id}
                >
                  <div className="card-content">
                    <img className="Examcard-image" src="https://via.placeholder.com/300x250" alt="" />
                    <h3>{template.name}</h3>
                    <h2>Exam Type: {template.examType}</h2>
                    <h2>Subject: {template.subject}</h2>
                    <h2>Time Limit: {template.timeLimit}</h2>
                    <span className="attended-label">Attended</span>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>

      <div id="pagecontext">
        <QuoteCarousel />
      </div>


      <div className="container" id="page4">
        <video autoPlay muted loop src="videos/accern-rhea-cover-big.mp4"></video>
      </div>


      <div id="pagepromo">
        <div id="pagepromocont">
          <h2>In Partner with</h2>
          <img src="images/examtech_logo.png" alt="Examtech Logo" />
          <h2>EXAMTECH</h2>
        </div>
        <button id="promobtn">Visit website</button>
      </div>

      <div id="page6">
        <div>
          <img src="images/1.png" alt="" />
          <div id="formcard">
            <form>
              <h1 className="title text-center mb-4">Councelling</h1>
              <div className="form-group position-relative">
                <label htmlFor="formName" className="d-block">
                  <i className="icon" data-feather="user"></i>
                </label>
                <input
                  type="text"
                  id="formName"
                  className="form-control form-control-lg thick"
                  placeholder="Name"
                />
              </div>
              <div className="form-group position-relative">
                <label htmlFor="formEmail" className="d-block">
                  <i className="icon" data-feather="mail"></i>
                </label>
                <input
                  type="email"
                  id="formEmail"
                  className="form-control form-control-lg thick"
                  placeholder="E-mail"
                />
              </div>
              <div className="form-group message">
                <textarea
                  id="formMessage"
                  className="form-control form-control-lg"
                  rows="7"
                  placeholder="Message"
                ></textarea>
              </div>
              <div className="text-center">
                <button type="submit" className="btn btn-primary" tabIndex="-1">
                  Send message
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <footer>
        <div className="footcontainer">
          <div className="footer-content">
            <h3>Contact Us</h3>
            <p>Email: Info@example.com</p>
            <p>Phone: +123456789</p>
            <p>Address: Your Address 123 street</p>
          </div>
          <div className="footer-content">
            <h3>Quick Links</h3>
            <ul className="list">
              <li><a href="/">Home</a></li>
              <li><a href="/about">About</a></li>
              <li><a href="/services">Services</a></li>
              <li><a href="/products">Products</a></li>
              <li><a href="/contact">Contact</a></li>
            </ul>
          </div>
          <div className="footer-content">
            <h3>Follow Us</h3>
            <ul className="social-icons">
              <li><a href="#"><i className="fab fa-facebook"></i></a></li>
              <li><a href="#"><i className="fab fa-twitter"></i></a></li>
              <li><a href="#"><i className="fab fa-instagram"></i></a></li>
              <li><a href="#"><i className="fab fa-linkedin"></i></a></li>
            </ul>
          </div>
        </div>
        <div className="bottom-bar">
          <p>&copy; 2024 examtech.org. All rights reserved</p>
        </div>
      </footer>
    </div>
  );
};

export default UserDashboard;
