// MathJaxLoader.js
import { useEffect } from 'react';

const MathJaxLoader = ({ children }) => {
    useEffect(() => {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = 'https://cdn.jsdelivr.net/npm/mathjax@3/es5/tex-mml-chtml.js';
        script.async = true;

        script.onload = () => {
            window.MathJax = {
                tex: {
                    inlineMath: [['$', '$'], ['\\(', '\\)']],
                },
                svg: {
                    fontCache: 'global',
                },
                startup: {
                    ready: () => {
                        window.MathJax.startup.defaultReady();
                        window.MathJax.typeset();
                    },
                },
            };
        };

        document.head.appendChild(script);

        return () => {
            document.head.removeChild(script);
        };
    }, []);

    useEffect(() => {
        if (window.MathJax && window.MathJax.typeset) {
            window.MathJax.typeset();
        }
    }, [children]);

    return children;
};

export default MathJaxLoader;
