import React from 'react';
import './PopupQuote.css'; // Make sure to create the corresponding CSS file

const quotes = [
  { text: "Education is the passport to the future, for tomorrow belongs to those who prepare for it today.", author: "Malcolm X" },
  { text: "The only limit to our realization of tomorrow is our doubts of today.", author: "Franklin D. Roosevelt" },
  { text: "Success is not final, failure is not fatal: It is the courage to continue that counts.", author: "Winston Churchill" },
  { text: "The roots of education are bitter, but the fruit is sweet.", author: "Aristotle" },
  { text: "Don't watch the clock; do what it does. Keep going.", author: "Sam Levenson" },
  // Add more quotes as needed
];

const getRandomQuote = () => {
  const randomIndex = Math.floor(Math.random() * quotes.length);
  return quotes[randomIndex];
};

const PopupQuote = ({ onClose }) => {
  const quote = getRandomQuote();

  return (
    <div className="popup-quote-overlay">
      <div className="popup-quote-content">
        <h3>"{quote.text}"</h3>
        <p>- {quote.author}</p>
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default PopupQuote;