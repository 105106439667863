import React, { useEffect } from "react";
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import './Aboutus.css';

gsap.registerPlugin(ScrollTrigger);

const Aboutus = () => {

  useEffect(() => {
    // Init ScrollTrigger
    gsap.registerPlugin(ScrollTrigger);

    let aboutusitems = document.querySelectorAll('.Aboutus-right-img-item');

    aboutusitems.forEach((item, index) => {
      item.style.zIndex = aboutusitems.length - index;
    });

    gsap.set(".Aboutus-right-img-item", {
      clipPath: "inset(0px 0px 0px 0px)"
    });

    const animation = gsap.to('.Aboutus-right-img-item:not(:last-child)', {
      clipPath: "inset(0px 0px 100% 0px)",
      stagger: 0.5,
      ease: "none"
    });

    ScrollTrigger.create({
      trigger: '.Aboutus-container',
      start: 'top top',
      end: 'bottom bottom',
      animation: animation,
      scrub: 1
    });


     // Background color change effect
     const colors = ['#FAE1EE', '#E0F0FF', '#FFEDE0', '#ffff']; // Define your colors here

     const colorChangeTimeline = gsap.timeline({
       scrollTrigger: {
         trigger: '.Aboutus-container',
         start: 'top top',
         end: 'bottom bottom',
         scrub: 1,
       }
     });
 
     colors.forEach((color, index) => {
       colorChangeTimeline.to('.Aboutus-container', { backgroundColor: color, duration: 1 }, index * 2);
     });


  }, []);

  return (
    <div className="Aboutus-wrapper">
      <section className="Aboutus-container">
        <div className="Aboutus-left">
          <div className="Aboutus-text">
            <div className="Aboutus-info">
              <div className="Aboutus-left-b1">
                <h2 className="Aboutus-title"><span className="stroke">online examination platform</span>Our comprehensive online examination platform serves both individual students and educational institutions.</h2>
              </div>
            </div>
            <div className="Aboutus-info">
              <div className="Aboutus-left-b1">
                <h2 className="Aboutus-title"><span className="stroke">AI integrated</span>Unlike traditional examination software, Examtech's MITHRA platform is integrated with AI</h2>
              </div>
            </div>
            <div className="Aboutus-info">
              <div className="Aboutus-left-b1">
                <h2 className="Aboutus-title"><span className="stroke">real-time analytics and feedback</span>AI is used to enhance every aspect of the exam process—from question selection and randomization to real-time analytics and feedback.</h2>
              </div>
            </div>
            <div className="Aboutus-info">
              <div className="Aboutus-left-b1">
                <h2 className="Aboutus-title"><span className="stroke">seamless Experience</span>It ensures a seamless, secure, and efficient examination experience for all users.</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="Aboutus-right">
          <div className="Aboutus-right-b1">
            <div className="Aboutus-right-img">
              <div className="Aboutus-right-img-item" title="1">
                <img src="images/mandala.jpg" alt="1" />
              </div>
              <div className="Aboutus-right-img-item" title="2">
                <img src="images/manic.jpeg" alt="2" />
              </div>
              <div className="Aboutus-right-img-item" title="3">
                <img src="images/matadoria.jpg" alt="3" />
              </div>
              <div className="Aboutus-right-img-item" title="4">
                <img src="images/Silhouette.jpg" alt="4" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Aboutus;
