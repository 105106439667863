import React, { useState } from 'react';
import {useParams, useNavigate  } from 'react-router-dom';
import './InstructionPage.css';

const InstructionPage = () => {
const { templateId } = useParams();
  const [isChecked, setIsChecked] = useState(false);
  const navigate = useNavigate();

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleProceedClick = () => {
    if (isChecked) {
      // Handle the proceed action
      navigate(`/template-questions/${templateId}`);
      console.log("Proceed to the exam");
    } else {
      alert("Please read and understand the instructions and check the box to proceed.");
    }
  };

  return (
    <div className="instruction-page-container">
      <header className="headerInstruction">
        <div className="header-contentInstruction">
          <h2>GENERAL INSTRUCTIONS</h2>
          <div className="language-selector">
            <label>Choose Your Default Language</label>
            <select>
              <option value="English">English</option>
              <option value="Hindi">Hindi</option>
              {/* Add more languages as needed */}
            </select>
          </div>
        </div>
      </header>
      <main className="main-contentInstruction">
        <p>Please read the instructions carefully</p>
        <div className="general-instructions">
          <h3>General Instructions:</h3>
          <ol>
            <li>
              <span className="instruction-symbol">&#9675;</span>
              Total duration of NEET - PHYSICS is 180 min.
            </li>
            <li>
              The clock will be set at the server. The countdown timer in the top right corner of screen will display the remaining time available for you to complete the examination. When the timer reaches zero, the examination will end by itself. You will not be required to end or submit your examination.
            </li>
            <li>
            The Questions Palette displayed on the right side of screen will show the status of each question using one of the following symbols:</li>

            <div className="symbols">
            <li>
              <ol>
                <li><span className="status-symbol not-visited"></span> You have not visited the question yet.</li>
                <li><span className="status-symbol not-answered"></span> You have not answered the question.</li>
                <li><span className="status-symbol answered"></span> You have answered the question.</li>
                <li><span className="status-symbol marked"></span> You have NOT answered the question, but have marked the question for review.</li>
                <li><span className="status-symbol answered-marked"></span> The question(s) "Answered and Marked for Review" will be considered for evaluation.</li>
              </ol>
            </li>
            </div>
            
            <li>
              You can click on your "Profile" image on top right corner of your screen to change the language during the exam for entire question paper. On clicking of Profile image you will get a drop-down to change the question content to the desired language.
            </li>

          </ol>
        </div>
        <div className="navigating">
          <h3>Navigating to a Question:</h3>
          <ol>
            <li>
              To answer a question, do the following:
              <ol type="a">
                <li>Click on the question number in the Question Palette at the right of your screen to go to that numbered question directly. Note that using this option does NOT save your answer to the current question.</li>
                <li>Click on <span className="save-next">Save & Next</span> to save your answer for the current question and then go to the next question.</li>
                <li>Click on <span className="mark-review">Mark for Review & Next</span> to save your answer for the current question, mark it for review, and then go to the next question.</li>
              </ol>
            </li>
          </ol>
        </div>
        <div className="answering">
          <h3>Answering a Question:</h3>
          <ol>
            <li>
              Procedure for answering a multiple choice type question:
              <ol type="a">
                <li>To select your answer, click on the button of one of the options.</li>
                <li>To deselect your chosen answer, click on the button of the chosen option again or click on the <span className="clear-response">Clear Response</span> button.</li>
                <li>To change your chosen answer, click on the button of another option.</li>
                <li>To save your answer, you MUST click on the <span className="save-next">Save & Next</span> button.</li>
                <li>To mark the question for review, click on the <span className="mark-review">Mark for Review & Next</span> button.</li>
              </ol>
            </li>
            <li>
              To change your answer to a question that has already been answered, first select that question for answering and then follow the procedure for answering that type of question.
            </li>
          </ol>
        </div>
        <div className="navigating-sections">
          <h3>Navigating through sections:</h3>
          <ol>
            <li>Sections in this question paper are displayed on the top bar of the screen. Questions in a section can be viewed by clicking on the section name. The section you are currently viewing is highlighted.</li>
            <li>After clicking the Save & Next button on the last question for a section, you will automatically be taken to the first question of the next section.</li>
            <li>You can shuffle between sections and questions anytime during the examination as per your convenience only during the time stipulated.</li>
            <li>Candidate can view the corresponding section summary as part of the legend that appears in every section above the question palette.</li>
          </ol>
        </div>
        <div className="important-note">
          <p>Please note all questions will appear in your default language. This language can be changed for a particular question later on.</p>
        </div>
        <div className="confirmation-instruction">
          
          <label htmlFor="confirm"><input type="checkbox" id="confirm" onChange={handleCheckboxChange} />
            I have read and understood the instructions. All computer hardware allotted to me is in proper working condition. I declare that I am not in possession of / not wearing / not carrying any prohibited gadget like mobile phone, bluetooth devices etc. / any prohibited material with me into the Examination Hall. I agree that in case of not adhering to the instructions, I shall be liable to be debarred from this Test and/or to disciplinary action, which may include ban from future Tests / Examinations.
          </label>
        </div>
        <div className="proceed-button-container">
          <button className="proceed-button" onClick={handleProceedClick}>PROCEED</button>
        </div>
      </main>
    </div>
  );
};

export default InstructionPage;
