import React, { useState, useEffect } from 'react';
import './quotestyles.css';

const quotes = [
  { text: "Education is the passport to the future, for tomorrow belongs to those who prepare for it today.", author: "Malcolm X" },
  { text: "The only limit to our realization of tomorrow is our doubts of today.", author: "Franklin D. Roosevelt" },
  { text: "Success is not final, failure is not fatal: It is the courage to continue that counts.", author: "Winston Churchill" },
  { text: "The roots of education are bitter, but the fruit is sweet.", author: "Aristotle" },
  { text: "Don't watch the clock; do what it does. Keep going.", author: "Sam Levenson" },
  // Add more quotes as needed
];

const QuoteCarousel = () => {
  const [currentQuoteIndex, setCurrentQuoteIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentQuoteIndex((prevIndex) => (prevIndex + 1) % quotes.length);
    }, 4500); // Change every 15 seconds

    return () => clearInterval(interval); // Cleanup on unmount
  }, []);

  return (
    <div className="quote-carousel">
      <h3>"{quotes[currentQuoteIndex].text}"</h3>
      <p>- {quotes[currentQuoteIndex].author}</p>
    </div>
  );
};

export default QuoteCarousel;
