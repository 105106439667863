import React, { useEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import SplitText from "gsap-trial/SplitText"
import Lenis from 'lenis'
import LocomotiveScroll from 'locomotive-scroll';
import { useNavigate } from 'react-router-dom';
import { motion, useScroll, useMotionValueEvent } from 'framer-motion';
import { fadeIn } from '../variants';

import './style.css';
import './style_x.css';

import ShuffleHero from './ShuffleHero';
import BouncyCardsFeatures from './BouncyCardsFeatures';
import Examcards from './Examcards';
import FAQApp from './FAQ/FAQApp';
import Followus from './Followus';
import QuoteCarousel from './Quotes/QuoteCarousel';
import CounterUpPage from './Counter/CounterUpPage';
import Aboutus from './AboutUs/Aboutus';
import { SiUnitednations } from 'react-icons/si';



gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(SplitText);

function App() {
  const navigate = useNavigate();
  const scrollRef = useRef(null); // Ref for LocomotiveScroll
  const videoRef = useRef(null); // Ref for the video element
  const [isPlaying, setIsPlaying] = useState(false);
  const [isAboutUsSection, setIsAboutUsSection] = useState(false);
  const [scrollbarColor, setScrollbarColor] = useState('#00004e');


  const lenis = new Lenis()

  lenis.on('scroll', (e) => {
    console.log(e)
  })

  lenis.on('scroll', ScrollTrigger.update)

  gsap.ticker.add((time) => {
    lenis.raf(time * 1000)
  })

  gsap.ticker.lagSmoothing(0)

  // const sectionRef = useRef();
  // const textRefs = useRef([]);



  const handleClick = () => {
    navigate('/login'); // Make sure this matches the route you set for login.js in your router
  };

  useEffect(() => {






    const scrollEl = scrollRef.current;

    if (scrollEl) {
      // Initialize LocomotiveScroll
      // const locoScroll = new LocomotiveScroll({
      //   el: scrollEl,
      //   smooth: true,
      // });

      // Each time Locomotive Scroll updates, tell ScrollTrigger to update too
      // locoScroll.on('scroll', ScrollTrigger.update);

      // Tell ScrollTrigger to use proxy methods for the scroll container
      // ScrollTrigger.scrollerProxy(scrollEl, {
      //   scrollTop(value) {
      //     return arguments.length ? locoScroll.scrollTo(value, 0, 0) : locoScroll.scroll.instance.scroll.y;
      //   },
      //   getBoundingClientRect() {
      //     return { top: 0, left: 0, width: window.innerWidth, height: window.innerHeight };
      //   },
      //   pinType: document.querySelector('#main').style.transform ? 'transform' : 'fixed',
      // });

      // Refresh ScrollTrigger and update LocomotiveScroll on window updates
      // ScrollTrigger.addEventListener('refresh', () => locoScroll.update());
      // ScrollTrigger.refresh();







      // GSAP animations using timeline
      const t3 = gsap.timeline();

      t3.from('#loader h3', {
        x: 40,
        opacity: 0,
        duration: 1,
        stagger: 0.1,
      });

      t3.to('#loader h3', {
        opacity: 0,
        x: -10,
        duration: 1,
        stagger: 0.1,
      });

      t3.to('#loader', {
        opacity: 0,
      });

      t3.to('#loader', {
        display: 'none',
      });

      // New GSAP timeline for part-2
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: '.part-2',
          start: '30% 45%',
          end: '90% 70%',
          scrub: 2,
          pin: true,
          // markers: true, // Add markers to debug
        },
      });

      tl.to('#image-anim-1', {
        opacity: 0,
      }, 'a').to('.image-anim', {
        rotateX: '0deg',
        top: '-10vh',
      }, 'a').to('.part-2-video', {
        opacity: 1,
        rotateX: '0deg',
        top: '-10vh'
      }, 'a');

      const videocon = document.querySelector('.part-2-video');
      const playbtn = document.querySelector('#part-2-playbtn');
      videocon.addEventListener("mouseenter", function () {
        gsap.to(playbtn, {
          scale: 1,
          opacity: 1
        });
      });
      videocon.addEventListener("mouseleave", function () {
        gsap.to(playbtn, {
          scale: 0,
          opacity: 0
        });
      });
      videocon.addEventListener("mousemove", function (dets) {
        gsap.to(playbtn, {
          left: dets.x - 70,
          top: dets.y - 80
        });
      });


      //   const tll=gsap.timeline({
      //     scrollTrigger: {
      //       trigger: '#main2',
      //       start: '50% 50%',
      //       end: '250% 50%',
      //       scrub: true,
      //       pin: true,
      //     },
      //   });
      //   tll.to('.svg', {
      //     maskSize: "1300%",
      // })

      // Cleanup function to destroy LocomotiveScroll on component unmount
      // return () => {
      //   locoScroll.destroy();
      // };
    }


    // aimate on scroll---------------

    const observerOptions = {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    };

    // const observerCallback = (entries, observer) => {
    //   entries.forEach((entry) => {
    //     if (entry.isIntersecting) {
    //       const index = textRefs.current.indexOf(entry.target);
    //       setTimeout(() => {
    //         entry.target.classList.add("animate");
    //         observer.unobserve(entry.target);
    //       }, index * 300); // Stagger animation by 300ms
    //     }
    //   });
    // };

    // const observer = new IntersectionObserver(observerCallback, observerOptions);

    // textRefs.current.forEach((ref) => {
    //   if (ref) {
    //     observer.observe(ref);
    //   }
    // });

    // if (videoRef.current) {
    //   observer.observe(videoRef.current);
    // }

    // return () => {
    //   textRefs.current.forEach((ref) => {
    //     if (ref) {
    //       observer.unobserve(ref);
    //     }
    //   });
    //   if (videoRef.current) {
    //     observer.unobserve(videoRef.current);
    //   }
    // };


    // about us color changing part
    const handleScroll = () => {
      const aboutUsSection = document.querySelector('.Aboutus-container');

      if (!aboutUsSection) return;
      const aboutUsSectionRect = aboutUsSection.getBoundingClientRect();
      const isAboutUsInView = (
        aboutUsSectionRect.top <= window.innerHeight &&
        aboutUsSectionRect.bottom >= 0
      );

      setIsAboutUsSection(isAboutUsInView);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };





  }, []);  // Empty dependency array ensures useEffect runs once

  const togglePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

const[hidden, setHidden]=useState(false)
const{scrollY}= useScroll();

useMotionValueEvent(scrollY, "change", (latest) => {
  const previous = scrollY.getPrevious();
  if(latest>previous && latest > 250){
    setHidden(true);
  }else{
    setHidden(false);
  }
});
  

  return (
    <div id="main" ref={scrollRef}>
      <div id="loader">
        <h3>Let's</h3>
        <h3>crack</h3>
        <h3>It.</h3>
      </div>

      <header className={`header-landing ${isAboutUsSection ? 'about-us-active' : ''}`}>
        <nav>
          <h1>MITHRA.</h1>
          <div className="nav-part2">
            <a href="/">HOME</a>
            <a href="/about">ABOUT US</a>
            <a href="/contact">CONTACT US</a>
            <a href="/help">NEED HELP?</a>
          </div>
          <button onClick={handleClick}>Login</button>
        </nav>
      </header>



      {/* ----------navbar with hide animation----------- */}
      {/* <motion.header
      variants={{
        visible: {y: 0},
        hidden: {y: "-100%"}
      }}
      animate={hidden ? "hidden" : "visible"}
      transition={{ duration: 0.35, ease: "easeInOut" }}
      
      className={`header-landing ${isAboutUsSection ? 'about-us-active' : ''}`}>
        <nav >
          <h1>MITHRA.</h1>
          <div className="nav-part2">
            <a href="/">HOME</a>
            <a href="/about">ABOUT US</a>
            <a href="/contact">CONTACT US</a>
            <a href="/help">NEED HELP?</a>
          </div>
          <button onClick={handleClick}>Login</button>
        </nav>
      </motion.header> */}


      {/*--------page 1--------------*/}
      <div id="page1">
        {/* <svg className="c-bttn__morph" fill="none" viewBox="0 0 131 136">
          <path className="g-path" data-morph="end" fill="#fff"
            d="M48 77.457v7.224h7.224l21.307-21.306-7.224-7.225L48 77.457Zm34.118-19.67a1.919 1.919 0 0 0 0-2.716l-4.508-4.508a1.919 1.919 0 0 0-2.716 0l-3.526 3.526 7.224 7.224 3.526-3.525Z">
          </path>
          <path className="g-path" data-morph="start" fill="#fff"
            d="M48 77.457v7.224h7.224l21.307-21.306-7.224-7.225L48 77.457Zm34.118-19.67a1.919 1.919 0 0 0 0-2.716l-4.508-4.508a1.919 1.919 0 0 0-2.716 0l-3.526 3.526 7.224 7.224 3.526-3.525Z"
            data-original="M65.72 109.14c10.08 0 17.76-3.78 23.04-11.34 5.4-7.68 8.1-17.64 8.1-29.88 0-12.24-2.7-22.2-8.1-29.88-5.28-7.68-12.96-11.52-23.04-11.52-9.96 0-17.7 3.84-23.22 11.52-5.4 7.68-8.1 17.64-8.1 29.88 0 12.12 2.7 22.02 8.1 29.7 5.52 7.68 13.26 11.52 23.22 11.52Zm46.08 7.02c-11.64 12.6-27.06 18.9-46.26 18.9s-34.62-6.3-46.26-18.9C7.76 103.56 2 87.48 2 67.92c0-19.56 5.76-35.64 17.28-48.24C30.92 7.08 46.34.78 65.54.78s34.62 6.3 46.26 18.9c11.64 12.6 17.46 28.68 17.46 48.24 0 19.56-5.82 35.64-17.46 48.24Z">
          </path>
        </svg> */}
        <h1>We are MITHRA</h1>
        <div className='text-wrapper-page1'>

          <p id="page1-3d-text">The Exam Portal</p>
          {/* <p id="page1-3d-text">AI integrated</p>
        <p id="page1-3d-text">Let's crack It</p> */}

        </div>

        <div id="moving-div">
          <div id="blur-left"></div>
          <div className="move">
            <img src="https://lazarev.kiev.ua/la24/forbes.svg" alt="" />
            <img src="https://lazarev.kiev.ua/la24/adweek.svg" alt="" />
            <img src="https://lazarev.kiev.ua/la24/pmi.svg" alt="" />
            <img src="https://lazarev.kiev.ua/la24/wf.svg" alt="" />
            <img src="https://lazarev.kiev.ua/la24/adweek.svg" alt="" />
            <img src="https://lazarev.kiev.ua/la24/rd.svg" alt="" />
            <img src="https://lazarev.kiev.ua/la24/pmi.svg" alt="" />
            <img src="https://lazarev.kiev.ua/la24/webby.svg" alt="" />
            <img src="https://lazarev.kiev.ua/la24/forbes.svg" alt="" />
            <img src="https://lazarev.kiev.ua/la24/adweek.svg" alt="" />
            <img src="https://lazarev.kiev.ua/la24/pmi.svg" alt="" />
            <img src="https://lazarev.kiev.ua/la24/wf.svg" alt="" />
            <img src="https://lazarev.kiev.ua/la24/adweek.svg" alt="" />
            <img src="https://lazarev.kiev.ua/la24/rd.svg" alt="" />
            <img src="https://lazarev.kiev.ua/la24/pmi.svg" alt="" />
            <img src="https://lazarev.kiev.ua/la24/webby.svg" alt="" />
          </div>
          <div id="blur-right"></div>
        </div>
        <button className="page1_btn" onClick={handleClick}>login</button>

        <div class="inner-header flex">
          <div className="waves-container">
            <svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
              viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
              <defs>
                <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
              </defs>
              <g className="parallax">
                <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
                <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
                <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
                <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
              </g>
            </svg>
          </div>
        </div>

      </div>

      {/*--------part-2----------- */}
      <div className="part-2">
        <img id="image-anim-2" className="image-anim" src="images/image4.jpg" alt="writtenimage" />
        <img id="image-anim-1" className="image-anim" src="images/image5.jpg" alt="blankimage" />
        <div className='part-2-video' onClick={togglePlayPause}>
          <div id='part-2-playbtn'>PLAY</div>
          <video ref={videoRef} muted loop className='part-2-video-play' src="videos/accern-rhea-cover-big.mp4"></video>
        </div>
      </div>

      {/* --------------------------------------------------- */}



      <Aboutus />


      {/* -----------page2-------------- */}

      {/* <div className="container" id="page2" ref={sectionRef}>
      <div className="p2-section">
        <div className="p2-sec-left">
          <h2 ref={(el) => (textRefs.current[0] = el)}>What we do?</h2>
          <p ref={(el) => (textRefs.current[1] = el)}>
          Our comprehensive online examination platform serves both individual students and educational institutions.<br></br> Unlike traditional examination software, Examtech’s platform integrates AI to enhance every aspect of the exam process—from question selection and randomization to real-time analytics and feedback. This ensures a seamless, secure, and efficient examination experience for all users.
            
          </p>
        </div>
        <div className="p2-sec-right" ref={videoRef}>
          <video autoPlay muted loop src="videos/accern-rhea-cover-big.mp4"></video>
        </div>
      </div>
    </div> */}

      {/* --------------------------------------------------- */}

      {/* CounterupPage */}

      <CounterUpPage />

      {/* --------------------------------------------------- */}

      {/*--------page3----------------*/}
      {/* <div className="container" id="page3">
        <h1>Exams available</h1>
        <div className="grid">
          <div className="card">
            <span className="icon">
              <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round"
                strokeLinejoin="round" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M14.5 3.5C14.5 3.5 14.5 5.5 12 5.5C9.5 5.5 9.5 3.5 9.5 3.5H7.5L4.20711 6.79289C3.81658 7.18342 3.81658 7.81658 4.20711 8.20711L6.5 10.5V20.5H17.5V10.5L19.7929 8.20711C20.1834 7.81658 20.1834 7.18342 19.7929 6.79289L16.5 3.5H14.5Z" />
              </svg>
            </span>
            <h4>JEE</h4>
            <p>
              Standard chunk of Lorem Ipsum used since the 1500s is showed below
              for those interested.
            </p>
            <div className="shine"></div>
            <div className="background">
              <div className="tiles">
                <div className="tile tile-1"></div>
                <div className="tile tile-2"></div>
                <div className="tile tile-3"></div>
                <div className="tile tile-4"></div>

                <div className="tile tile-5"></div>
                <div className="tile tile-6"></div>
                <div className="tile tile-7"></div>
                <div className="tile tile-8"></div>

                <div className="tile tile-9"></div>
                <div className="tile tile-10"></div>
              </div>

              <div className="line line-1"></div>
              <div className="line line-2"></div>
              <div className="line line-3"></div>
            </div>
          </div>

          <div className="card">
            <span className="icon">
              <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round"
                strokeLinejoin="round" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M14.5 3.5C14.5 3.5 14.5 5.5 12 5.5C9.5 5.5 9.5 3.5 9.5 3.5H7.5L4.20711 6.79289C3.81658 7.18342 3.81658 7.81658 4.20711 8.20711L6.5 10.5V20.5H17.5V10.5L19.7929 8.20711C20.1834 7.81658 20.1834 7.18342 19.7929 6.79289L16.5 3.5H14.5Z" />
              </svg>
            </span>
            <h4>KCET</h4>
            <p>
              Standard chunk of Lorem Ipsum used since the 1500s is showed below
              for those interested.
            </p>
            <div className="shine"></div>
            <div className="background">
              <div className="tiles">
                <div className="tile tile-1"></div>
                <div className="tile tile-2"></div>
                <div className="tile tile-3"></div>
                <div className="tile tile-4"></div>

                <div className="tile tile-5"></div>
                <div className="tile tile-6"></div>
                <div className="tile tile-7"></div>
                <div className="tile tile-8"></div>

                <div className="tile tile-9"></div>
                <div className="tile tile-10"></div>
              </div>

              <div className="line line-1"></div>
              <div className="line line-2"></div>
              <div className="line line-3"></div>
            </div>
          </div>

          <div className="card">
            <span className="icon">
              <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round"
                strokeLinejoin="round" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M4.5 9.5V5.5C4.5 4.94772 4.94772 4.5 5.5 4.5H9.5C10.0523 4.5 10.5 4.94772 10.5 5.5V9.5C10.5 10.0523 10.0523 10.5 9.5 10.5H5.5C4.94772 10.5 4.5 10.0523 4.5 9.5Z" />
                <path
                  d="M13.5 18.5V14.5C13.5 13.9477 13.9477 13.5 14.5 13.5H18.5C19.0523 13.5 19.5 13.9477 19.5 14.5V18.5C19.5 19.0523 19.0523 19.5 18.5 19.5H14.5C13.9477 19.5 13.5 19.0523 13.5 18.5Z" />
                <path d="M4.5 19.5L7.5 13.5L10.5 19.5H4.5Z" />
                <path
                  d="M16.5 4.5C18.1569 4.5 19.5 5.84315 19.5 7.5C19.5 9.15685 18.1569 10.5 16.5 10.5C14.8431 10.5 13.5 9.15685 13.5 7.5C13.5 5.84315 14.8431 4.5 16.5 4.5Z" />
              </svg>
            </span>
            <h4>NEET</h4>
            <p>
              Standard chunk of Lorem Ipsum used since the 1500s is showed below
              for those interested.
            </p>
            <div className="shine"></div>
            <div className="background">
              <div className="tiles">
                <div className="tile tile-1"></div>
                <div className="tile tile-2"></div>
                <div className="tile tile-3"></div>
                <div className="tile tile-4"></div>

                <div className="tile tile-5"></div>
                <div className="tile tile-6"></div>
                <div className="tile tile-7"></div>
                <div className="tile tile-8"></div>

                <div className="tile tile-9"></div>
                <div className="tile tile-10"></div>
              </div>

              <div className="line line-1"></div>
              <div className="line line-2"></div>
              <div className="line line-3"></div>
            </div>
          </div>
        </div>
      </div> */}






      {/* --------------------------------------------------- */}

      <div className="container" id="page4">
        <video autoPlay muted loop src="videos/accern-rhea-cover-big.mp4"></video>
      </div>

      {/* --------------------------------------------------- */}

      {/*----------exam cards--------*/}

      <Examcards />



      {/* --------------------------------------------------- */}


      {/*----------ShuffleHero page--------*/}


      <ShuffleHero />

      {/* --------------------------------------------------- */}


      {/* <!------------ page4 -----------> */}

      <div className="container" id="page4">
        <video autoPlay muted loop src="videos/accern-rhea-cover-big.mp4"></video>
      </div>

      {/* --------------------------------------------------- */}

      {/*---------features-------*/}

      <BouncyCardsFeatures />

      {/* --------------------------------------------------- */}

      {/*--------page quote-------*/}
      <div id="pagecontext">
        <QuoteCarousel />
      </div>

      {/* --------------------------------------------------- */}

      {/* <!------------ page5 ------------> */}
      <div className="container" id="page5">
        <div>
          <div className="outerdiv">
            <div className="innerdiv">
              {/* <!-- div1 --> */}
              <div className="div1 eachdiv">
                <div className="userdetails">
                  <div className="imgbox">
                    <img
                      src="https://raw.githubusercontent.com/RahulSahOfficial/testimonials_grid_section/5532c958b7d3c9b910a216b198fdd21c73112d84/images/image-daniel.jpg"
                      alt="" />
                  </div>
                  <div className="detbox">
                    <p className="name">Daniel Clifford</p>
                    <p className="designation">Verified Graduate</p>
                  </div>
                </div>
                <div className="review">
                  <h4>I received a job offer mid-course, and the subjects I learned were current, if not more so, in the
                    company I joined. I honestly feel I got every penny’s worth.</h4>
                  <p>“ I was an EMT for many years before I joined the bootcamp. I’ve been looking to make a transition
                    and have heard some people who had an amazing experience here. I signed up for the free intro course
                    and found it incredibly fun! I enrolled shortly thereafter. The next 12 weeks was the best - and most
                    grueling - time of my life. Since completing the course, I’ve successfully switched careers, working
                    as a Software Engineer at a VR startup. ”</p>
                </div>
              </div>

              {/* <!-- div2 --> */}
              <div className="div2 eachdiv">
                <div className="userdetails">
                  <div className="imgbox">
                    <img
                      src="https://raw.githubusercontent.com/RahulSahOfficial/testimonials_grid_section/5532c958b7d3c9b910a216b198fdd21c73112d84/images/image-jonathan.jpg"
                      alt="" />
                  </div>
                  <div className="detbox">
                    <p className="name">Jonathan Walters</p>
                    <p className="designation">Verified Graduate</p>
                  </div>
                </div>
                <div className="review">
                  <h4>The team was very supportive and kept me motivated</h4>
                  <p>“ I started as a total newbie with virtually no coding skills. I now work as a mobile engineer for a
                    big company. This was one of the best investments I’ve made in myself. ”</p>
                </div>
              </div>

              {/* <!-- div3 --> */}
              <div className="div3 eachdiv">
                <div className="userdetails">
                  <div className="imgbox">
                    <img
                      src="https://raw.githubusercontent.com/RahulSahOfficial/testimonials_grid_section/5532c958b7d3c9b910a216b198fdd21c73112d84/images/image-kira.jpg"
                      alt="" />
                  </div>
                  <div className="detbox">
                    <p className="name dark">Kira Whittle</p>
                    <p className="designation dark">Verified Graduate</p>
                  </div>
                </div>
                <div className="review dark">
                  <h4>Such a life-changing experience. Highly recommended!</h4>
                  <p>“ Before joining the bootcamp, I’ve never written a line of code. I needed some structure from
                    professionals who can help me learn programming step by step. I was encouraged to enroll by a former
                    student of theirs who can only say wonderful things about the program. The entire curriculum and staff
                    did not disappoint. They were very hands-on and I never had to wait long for assistance. The agile
                    team project, in particular, was outstanding. It took my learning to the next level in a way that no
                    tutorial could ever have. In fact, I’ve often referred to it during interviews as an example of
                    experience. It certainly helped me land a job as a full-stack 100% recommend! ”</p>
                </div>
              </div>

              {/* <!-- div4 --> */}
              <div className="div4 eachdiv">
                <div className="userdetails">
                  <div className="imgbox">
                    <img
                      src="https://raw.githubusercontent.com/RahulSahOfficial/testimonials_grid_section/5532c958b7d3c9b910a216b198fdd21c73112d84/images/image-jeanette.jpg"
                      alt="" />
                  </div>
                  <div className="detbox">
                    <p className="name dark">Jeanette Harmon</p>
                    <p className="designation dark">Verified Graduate</p>
                  </div>
                </div>
                <div className="review dark">
                  <h4>An overall wonderful and rewarding experience</h4>
                  <p>“ Thank you for the wonderful experience! I now have a job I really enjoy, and make a good living
                    while doing something I love. ”</p>
                </div>
              </div>

              {/* <!-- div5 --> */}
              <div className="div5 eachdiv">
                <div className="userdetails">
                  <div className="imgbox">
                    <img
                      src="https://raw.githubusercontent.com/RahulSahOfficial/testimonials_grid_section/5532c958b7d3c9b910a216b198fdd21c73112d84/images/image-patrick.jpg"
                      alt="" />
                  </div>
                  <div className="detbox">
                    <p className="name">Patrick Abrams</p>
                    <p className="designation">Verified Graduate</p>
                  </div>
                </div>
                <div className="review">
                  <h4>Awesome teaching support from TAs who did the bootcamp themselves. Getting guidance from them and
                    learning from their experiences was easy.</h4>
                  <p>“ The staff seem genuinely concerned about my progress which I find really refreshing. The program
                    gave me the confidence necessary to be able to go out in the world and present myself as a capable
                    junior developer. The standard is above the rest. You will get the personal attention you need from an
                    incredible community of smart and amazing people. ”</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      {/* --------------------------------------------------- */}

      {/*---------page promo---------*/}
      <div id="pagepromo">
        <div id="pagepromocont">
          <h2>In Partner with</h2>
          <img src="images/examtech_logo.png" alt="Examtech Logo" />
          <h2>EXAMTECH</h2>
        </div>
        <button id="promobtn">Visit website</button>
      </div>

      {/* --------------------------------------------------- */}

      {/*---------page 6----------*/}
      <div id="page6">
        <div>
          <img src="images/1.png" alt="" />
          <div id="formcard">
            <form>
              <h1 className="title text-center mb-4">Councelling</h1>
              {/* Name */}
              <div className="form-group position-relative">
                <label htmlFor="formName" className="d-block">
                  <i className="icon" data-feather="user"></i>
                </label>
                <input
                  type="text"
                  id="formName"
                  className="form-control form-control-lg thick"
                  placeholder="Name"
                />
              </div>
              {/* E-mail */}
              <div className="form-group position-relative">
                <label htmlFor="formEmail" className="d-block">
                  <i className="icon" data-feather="mail"></i>
                </label>
                <input
                  type="email"
                  id="formEmail"
                  className="form-control form-control-lg thick"
                  placeholder="E-mail"
                />
              </div>
              {/* Message */}
              <div className="form-group message">
                <textarea
                  id="formMessage"
                  className="form-control form-control-lg"
                  rows="7"
                  placeholder="Message"
                ></textarea>
              </div>
              {/* Submit btn */}
              <div className="text-center">
                <button type="submit" className="btn btn-primary" tabIndex="-1">
                  Send message
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* --------------------------------------------------- */}

      {/*-----------FAQ---------*/}
      <motion.div
        variants={fadeIn("right", .2)}
        initial="hidden"
        whileInView={"show"}
        viewport={{ once: false, amount: 0.5 }}>
        <FAQApp />
      </motion.div>
      {/* --------------------------------------------------- */}

      {/*---------follow us--------*/}

      <motion.div
        variants={fadeIn("right", .2)}
        initial="hidden"
        whileInView={"show"}
        viewport={{ once: false, amount: 0.2 }}>
        <Followus />
      </motion.div>
      {/* --------------------------------------------------- */}

      {/*--------footer----------*/}
      <footer className="footer-landing">
        <div className="footcontainer">
          <div className="footer-content">
            <h3>Contact Us</h3>
            <p>Email: Info@example.com</p>
            <p>Phone: +123456789</p>
            <p>Address: Your Address 123 street</p>
          </div>
          <div className="footer-content">
            <h3>Quick Links</h3>
            <ul className="list">
              <li><a href="/">Home</a></li>
              <li><a href="/about">About</a></li>
              <li><a href="/services">Services</a></li>
              <li><a href="/products">Products</a></li>
              <li><a href="/contact">Contact</a></li>
            </ul>
          </div>
          <div className="footer-content">
            <h3>Follow Us</h3>
            <ul className="social-icons">
              <li><a href="#"><i className="fab fa-facebook"></i></a></li>
              <li><a href="#"><i className="fab fa-twitter"></i></a></li>
              <li><a href="#"><i className="fab fa-instagram"></i></a></li>
              <li><a href="#"><i className="fab fa-linkedin"></i></a></li>
            </ul>
          </div>
        </div>
        <div className="bottom-bar">
          <p>&copy; 2024 examtech.org. All rights reserved</p>
        </div>
      </footer>


    </div >
  );
}

export default App;
