import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import axios from "axios";
import UserDashboard from "./components/UserDashboard";
import AdminDashboard from "./components/AdminDashboard";
import Login from "./components/Login";
import CreatedTemplates from "./components/CreatedTemplates"; // Import CreatedTemplates component
import TemplateQuestions from "./components/TemplateQuestions";
import TemplateDetail from "./components/TemplateDetail"
import Main from "./components/userdashbord/Main";
import Landingpage from "./components/landingpage/Landingpage";
import ExamSummary from "./components/userdashbord/ExamSummary";
import InstructionPage from "./components/userdashbord/Instructionpage";
import ShuffleHero from "./components/landingpage/ShuffleHero";
import BouncyCardsFeatures from "./components/landingpage/BouncyCardsFeatures";
import FAQ from "./components/landingpage/FAQ/FAQApp";
import Followus from "./components/landingpage/Followus";
import QuoteCarousel from "./components/landingpage/Quotes/QuoteCarousel";
import CounterUpPage from "./components/landingpage/Counter/CounterUpPage";


const RouteApp = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      setIsAuthenticated(true);
    }
  }, []);

  const handleLogin = () => {
    setIsAuthenticated(true);
  };

  return (
    <Router>
      <Routes>
        <Route
          path="/user-dashboard"
          element={
            isAuthenticated ? (
              <UserDashboard />
            ) : (
              <Navigate
                to="/login"
                replace
                state={{ from: "/user-dashboard" }}
              />
            )
          }
        />
        <Route
          path="/admin-dashboard"
          element={
            isAuthenticated ? (
              <AdminDashboard />
            ) : (
              <Navigate
                to="/login"
                replace
                state={{ from: "/admin-dashboard" }}
              />
            )
          }
        />
        <Route
          path="/template-questions/:templateId"
          element={
            isAuthenticated ? (
              <Main />
            ) : (
              <Navigate
                to="/login"
                replace
                state={{ from: "/template-questions" }}
              />
            )
          }
        />
        <Route path="/login" element={<Login handleLogin={handleLogin} />} />
        <Route path="/created-templates" element={<CreatedTemplates />} />
        <Route path="/template/:templateId" element={<TemplateDetail />} />
        <Route path="/" element={<Landingpage />} />
        <Route path="/exam-summary" element={<ExamSummary />} />
        <Route path="/instruction-page/:templateId" element={<InstructionPage />} />
        <Route path="/shuffle-hero" element={<ShuffleHero />} />
        <Route path="/bouncy-cards-features" element={<BouncyCardsFeatures />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/followus" element={<Followus />} />
        <Route path="/quote-carousel" element={<QuoteCarousel />} />
        <Route path="/counter-up-page" element={<CounterUpPage />} />
       
      </Routes>
    </Router>
  );
};

export default RouteApp;
