import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const AdminDashboard = () => {
  const [templateName, setTemplateName] = useState("");
  const [description, setDescription] = useState("");
  const [years, setYears] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [subjectFields, setSubjectFields] = useState([
    { year: "", subject: "", numberOfQuestions: 0 },
  ]);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch years
    axios
      .get("http://localhost:1020/years")
      .then((response) => {
        setYears(response.data);
      })
      .catch((error) => {
        console.error("Error fetching years:", error);
      });
  }, []);

  const handleYearChange = (index, value) => {
    const newSubjectFields = [...subjectFields];
    newSubjectFields[index].year = value;
    setSubjectFields(newSubjectFields);

    if (value) {
      // Fetch subjects based on the selected year
      axios
        .get("http://localhost:1020/subjects", {
          params: {
            yearId: value,
          },
        })
        .then((response) => {
          setSubjects((prevSubjects) => {
            const newSubjects = [...prevSubjects];
            newSubjects[index] = response.data;
            return newSubjects;
          });
        })
        .catch((error) => {
          console.error("Error fetching subjects:", error);
        });
    } else {
      const newSubjects = [...subjects];
      newSubjects[index] = [];
      setSubjects(newSubjects);
    }
  };

  const handleSubjectChange = (index, value) => {
    const newSubjectFields = [...subjectFields];
    newSubjectFields[index].subject = value;
    setSubjectFields(newSubjectFields);
  };

  const handleNumberOfQuestionsChange = (index, value) => {
    const newSubjectFields = [...subjectFields];
    newSubjectFields[index].numberOfQuestions = value;
    setSubjectFields(newSubjectFields);
  };

  const addSubjectField = () => {
    setSubjectFields([
      ...subjectFields,
      { year: "", subject: "", numberOfQuestions: 0 },
    ]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      await axios.post("http://localhost:1020/exam-templates", {
        name: templateName,
        description,
        plan: "basic",
        subjects: subjectFields, // Include subject and year data in the request
      });

      navigate("/created-templates");
    } catch (error) {
      console.error("Error creating template:", error);
    }
  };

  return (
    <div>
      <h2>Create Template</h2>
      <form onSubmit={handleSubmit}>
        <label>
          Template Name:
          <input
            type="text"
            value={templateName}
            onChange={(e) => setTemplateName(e.target.value)}
            required
          />
        </label>
        <label>
          Description:
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </label>

        {subjectFields.map((field, index) => (
          <div key={index}>
            <label>
              Year:
              <select
                value={field.year}
                onChange={(e) => handleYearChange(index, e.target.value)}
                required
              >
                <option value="">Select Year</option>
                {years.map((year) => (
                  <option key={year.year_id} value={year.year_id}>
                    {year.year_name}
                  </option>
                ))}
              </select>
            </label>
            <label>
              Subject:
              <select
                value={field.subject}
                onChange={(e) => handleSubjectChange(index, e.target.value)}
                required
              >
                <option value="">Select Subject</option>
                {subjects[index] &&
                  subjects[index].map((subject) => (
                    <option key={subject.subject_id} value={subject.subject_id}>
                      {subject.subject_name}
                    </option>
                  ))}
              </select>
            </label>
            <label>
              Number of Questions:
              <input
                type="number"
                value={field.numberOfQuestions}
                onChange={(e) =>
                  handleNumberOfQuestionsChange(index, e.target.value)
                }
                min="1"
                required
              />
            </label>
          </div>
        ))}

        <button type="button" onClick={addSubjectField}>
          Add Another Subject
        </button>
        <button type="submit">Create Template</button>
      </form>
    </div>
  );
};

export default AdminDashboard;
