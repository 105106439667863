import React from 'react';
import './Header.css';

const Header = () => {
    return (
        <div className="headerQuestions">
            <div className="profile-photo">
                <img src="https://via.placeholder.com/100" alt="Candidate Profile" />
            </div>
            <div className="header-details">
                <div className="header-item">Candidate Name: [Your Name]</div>
                <div className="header-item">Exam Name: NEET</div>
                <div className="header-item">Subject Name: CHEMISTRY</div>
                <div className="header-item">Remaining Time: 02:59:34</div>
            </div>
        </div>
    );
};

export default Header;
