import React, { useState } from "react";
import axios from "axios";
import {jwtDecode} from "jwt-decode"; // Make sure to import jwt_decode correctly
import { useNavigate } from "react-router-dom";
import './Login.css';


import { ToastContainer, toast, Slide } from 'react-toastify';


const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post("http://localhost:1020/login", {
        username,
        password,
      });
      const { token } = response.data;
      localStorage.setItem("token", token);

      // Decode the JWT token to get the payload (assuming role is stored in JWT)
      const decodedToken = jwtDecode(token); // Use jwt_decode to decode the token
      const role = decodedToken.role;
      const id = decodedToken.id;
      const userUsername = decodedToken.username;

      console.log(id)
      localStorage.setItem("userId", id);
      localStorage.setItem("username", userUsername);

      if (role === "student") {
        toast.success('Login Successful');
        navigate("/user-dashboard");
      } else {
        navigate("/admin-dashboard");
      }
    } catch (error) {
      console.error("Login failed:", error);
      toast.error("Login failed, please try again.");
    }
  };

  return (
    <div className="loginContainer" >
      <div className="loginDivider">
        <div className="loginLeftContainer">
          <h2 className="loginHereText">Login Here !</h2>
          <form className="loginForm"  onSubmit={handleLogin}>
          <div className="loginInputLabel">Username</div>
          <input
            className="loginInputText"
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
          <div className="loginInputLabel">Password</div>
          <input
            className="loginInputText"
            type={showPassword ? "text" : "password"}
            id="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <input
              id="check"
              type="checkbox"
              className="showPassword"
              value={showPassword}
              onChange={() => setShowPassword((prev) => !prev)}
            />
            <span className="showPassword">Show Password</span>
          <button className="loginButton" type="submit">Login</button>
          </form>
        </div>
        <div className="loginRightContainer">
          <div className="loginRightSideContent">
            <div className="lockIconLogin"></div>
            <h5>Welcome to </h5>
            <h1> MITHRA!</h1>
            <div className="login-tagline">LEARN - PRACTICE - SUCCEED</div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        transition={Slide}
      />
      </div>
        );
};

        export default Login;
