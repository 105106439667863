import React, { useRef } from "react";
import {
  motion,
  useMotionTemplate,
  useMotionValue,
  useSpring,
} from "framer-motion";
import { FiMousePointer } from "react-icons/fi";
import './style_x.css';

const Examcards = () => {
  return (

    <div className="Examcards bg-gradient-to-br from-indigo-500 to-violet-500 text-slate-900">
    <h1 className="Examcard-title">Exams We Provide</h1>
       
    <div className="Examcard-container ">
      <TiltCard />
      <TiltCard1 />
      <TiltCard2 />
    </div>
    </div>



    
  );
};

const ROTATION_RANGE = 32.5;
const HALF_ROTATION_RANGE = 32.5 / 2;

const TiltCard = () => {
  const ref = useRef(null);

  const x = useMotionValue(0);
  const y = useMotionValue(0);

  const xSpring = useSpring(x);
  const ySpring = useSpring(y);

  const transform = useMotionTemplate`rotateX(${xSpring}deg) rotateY(${ySpring}deg)`;

  const handleMouseMove = (e) => {
    if (!ref.current) return [0, 0];

    const rect = ref.current.getBoundingClientRect();

    const width = rect.width;
    const height = rect.height;

    const mouseX = (e.clientX - rect.left) * ROTATION_RANGE;
    const mouseY = (e.clientY - rect.top) * ROTATION_RANGE;

    const rX = (mouseY / height - HALF_ROTATION_RANGE) * -1;
    const rY = mouseX / width - HALF_ROTATION_RANGE;

    x.set(rX);
    y.set(rY);
  };

  const handleMouseLeave = () => {
    x.set(0);
    y.set(0);
  };

  return (
    <motion.div
      ref={ref}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
      style={{
        transformStyle: "preserve-3d",
        transform,
      }}
      className="Examcard-back"
    >
      <div
        style={{
          transform: "translateZ(75px)",
          transformStyle: "preserve-3d",
        }}
        className="Examcard-front"
      >
        
        <img 
        style={{
            transform: "translateZ(50px)",
          }}
          className="Examcard-image"
          src="https://via.placeholder.com/300x250"
          alt=""
        />

        <h2 
        style={{
            transform: "translateZ(50px)",
          }}
          className="Examcard-heading"
        >
            JEE
        </h2>

        
        <p
          style={{
            transform: "translateZ(50px)",
          }}
          className="Examcard-para"
        >
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Expedita explicabo atque odit quisquam, nemo sequi corrupti suscipit fugiat autem recusandae.
        </p>
      </div>

    </motion.div>

    
  );
};

const TiltCard1 = () => {
    const ref = useRef(null);
  
    const x = useMotionValue(0);
    const y = useMotionValue(0);
  
    const xSpring = useSpring(x);
    const ySpring = useSpring(y);
  
    const transform = useMotionTemplate`rotateX(${xSpring}deg) rotateY(${ySpring}deg)`;
  
    const handleMouseMove = (e) => {
      if (!ref.current) return [0, 0];
  
      const rect = ref.current.getBoundingClientRect();
  
      const width = rect.width;
      const height = rect.height;
  
      const mouseX = (e.clientX - rect.left) * ROTATION_RANGE;
      const mouseY = (e.clientY - rect.top) * ROTATION_RANGE;
  
      const rX = (mouseY / height - HALF_ROTATION_RANGE) * -1;
      const rY = mouseX / width - HALF_ROTATION_RANGE;
  
      x.set(rX);
      y.set(rY);
    };
  
    const handleMouseLeave = () => {
      x.set(0);
      y.set(0);
    };
  
    return (
      <motion.div
        ref={ref}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
        style={{
          transformStyle: "preserve-3d",
          transform,
        }}
        className="Examcard-back"
      >
        <div
          style={{
            transform: "translateZ(75px)",
            transformStyle: "preserve-3d",
          }}
          className="Examcard-front"
        >
          
          <img 
          style={{
              transform: "translateZ(50px)",
            }}
            className="Examcard-image"
            src="https://via.placeholder.com/300x250"
            alt=""
          />
  
          <h2 
          style={{
              transform: "translateZ(50px)",
            }}
            className="Examcard-heading"
          >
              CET
          </h2>
  
          
          <p
            style={{
              transform: "translateZ(50px)",
            }}
            className="Examcard-para"
          >
            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Expedita explicabo atque odit quisquam, nemo sequi corrupti suscipit fugiat autem recusandae.
          </p>
        </div>
  
      </motion.div>
  
      
    );
  };

  const TiltCard2 = () => {
    const ref = useRef(null);
  
    const x = useMotionValue(0);
    const y = useMotionValue(0);
  
    const xSpring = useSpring(x);
    const ySpring = useSpring(y);
  
    const transform = useMotionTemplate`rotateX(${xSpring}deg) rotateY(${ySpring}deg)`;
  
    const handleMouseMove = (e) => {
      if (!ref.current) return [0, 0];
  
      const rect = ref.current.getBoundingClientRect();
  
      const width = rect.width;
      const height = rect.height;
  
      const mouseX = (e.clientX - rect.left) * ROTATION_RANGE;
      const mouseY = (e.clientY - rect.top) * ROTATION_RANGE;
  
      const rX = (mouseY / height - HALF_ROTATION_RANGE) * -1;
      const rY = mouseX / width - HALF_ROTATION_RANGE;
  
      x.set(rX);
      y.set(rY);
    };
  
    const handleMouseLeave = () => {
      x.set(0);
      y.set(0);
    };
  
    return (
      <motion.div
        ref={ref}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
        style={{
          transformStyle: "preserve-3d",
          transform,
        }}
        className="Examcard-back"
      >
        <div
          style={{
            transform: "translateZ(75px)",
            transformStyle: "preserve-3d",
          }}
          className="Examcard-front"
        >
          
          <img 
          style={{
              transform: "translateZ(50px)",
            }}
            className="Examcard-image"
            src="https://via.placeholder.com/300x250"
            alt=""
          />
  
          <h2 
          style={{
              transform: "translateZ(50px)",
            }}
            className="Examcard-heading"
          >
              NEET
          </h2>
  
          
          <p
            style={{
              transform: "translateZ(50px)",
            }}
            className="Examcard-para"
          >
            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Expedita explicabo atque odit quisquam, nemo sequi corrupti suscipit fugiat autem recusandae.
          </p>
        </div>
  
      </motion.div>
  
      
    );
  };

export default Examcards;