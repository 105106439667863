import React, { useState, useEffect } from 'react';
import MathJaxLoader from './MathJaxLoader';
import './Question.css';

const Question = ({ question, nextQuestion, previousQuestion, clearSelection, markForReview, markForReviewAndNext, submitExam, setResponses, responses }) => {
    const [selectedAnswer, setSelectedAnswer] = useState(null);

    useEffect(() => {
        const existingResponse = responses.find(response => response.questionId === question.id);
        if (existingResponse) {
            setSelectedAnswer(existingResponse.selectedOption);
        } else {
            setSelectedAnswer(null);
        }

        // Ensure MathJax typeset is called on question change
        if (window.MathJax && window.MathJax.typeset) {
            setTimeout(() => {
                window.MathJax.typeset();
            }, 0);
        }
    }, [question, responses]);

    if (!question) {
        return <div>Loading...</div>;
    }

    const handleOptionChange = (e) => {
        setSelectedAnswer(e.target.value);
        setResponses((prevResponses) => {
            const existingResponseIndex = prevResponses.findIndex(response => response.questionId === question.id);
            if (existingResponseIndex >= 0) {
                const newResponses = [...prevResponses];
                newResponses[existingResponseIndex].selectedOption = e.target.value;
                return newResponses;
            }
            return [...prevResponses, { questionId: question.id, selectedOption: e.target.value }];
        });
    };

    const handleSaveAndNext = () => {
        if (selectedAnswer) {
            nextQuestion(question.id, selectedAnswer);
        } else {
            alert('Please choose an option before proceeding to the next question');
        }
    };

    const handleClearSelection = () => {
        setSelectedAnswer(null);
        clearSelection(question.id);
    };

    const handleMarkForReview = () => {
        if (selectedAnswer) {
            markForReview();
        } else {
            alert('Please choose an option before marking for review');
        }
    };

    const handleMarkForReviewAndNext = () => {
        markForReviewAndNext(question.id, selectedAnswer);
    };

    return (
        <MathJaxLoader>
            <div className="question-container">
                <div className="question-text" dangerouslySetInnerHTML={{ __html: question.pre_question_text }} />
                <div className="options">
                    <div>
                        <input 
                            type="radio" 
                            name="option" 
                            value="A" 
                            checked={selectedAnswer === 'A'} 
                            onChange={handleOptionChange} 
                        /> 
                        <span dangerouslySetInnerHTML={{ __html: question.option1_text }} />
                    </div>
                    <div>
                        <input 
                            type="radio" 
                            name="option" 
                            value="B" 
                            checked={selectedAnswer === 'B'} 
                            onChange={handleOptionChange} 
                        /> 
                        <span dangerouslySetInnerHTML={{ __html: question.option2_text }} />
                    </div>
                    <div>
                        <input 
                            type="radio" 
                            name="option" 
                            value="C" 
                            checked={selectedAnswer === 'C'} 
                            onChange={handleOptionChange} 
                        /> 
                        <span dangerouslySetInnerHTML={{ __html: question.option3_text }} />
                    </div>
                    <div>
                        <input 
                            type="radio" 
                            name="option" 
                            value="D" 
                            checked={selectedAnswer === 'D'} 
                            onChange={handleOptionChange} 
                        /> 
                        <span dangerouslySetInnerHTML={{ __html: question.option4_text }} />
                    </div>
                </div>
                <div className="navigation">
                    <button className="nav-btnSN" onClick={handleSaveAndNext}>SAVE & NEXT</button>
                    <button className="nav-btnC" onClick={handleClearSelection}>CLEAR</button>
                    <button className="nav-btnSM" onClick={handleMarkForReview}>SAVE & MARK FOR REVIEW</button>
                    <button className="nav-btnM" onClick={handleMarkForReviewAndNext}>MARK FOR REVIEW & NEXT</button>
                </div>
                <div className="pannel-footer">
                    <div className="leftPanel">
                    <button className="nav-btnB" onClick={previousQuestion}>BACK</button>
                    <button className="nav-btnN" onClick={() => nextQuestion(question.id, selectedAnswer)}>NEXT</button>
                    </div>

                    <div className="right">
                    <button className="nav-btn submit-btnS" onClick={submitExam}>SUBMIT</button>
                    </div>
                </div>
            </div>
        </MathJaxLoader>
    );
};

export default Question;
