import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './ExamSummary.css';

const ExamSummary = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { summaryData, payload, previousState } = location.state || {};
  const {
    totalQuestions,
    answered,
    notAnswered,
    markedForReview,
    markedForReviewAndAnswered,
    notVisited,
  } = summaryData || {};

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [resultData, setResultData] = useState(null);
  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    fetch(`http://localhost:1020/exam-template-questions/${payload.examTemplateId}`)
      .then(response => response.json())
      .then(data => {
        const allQuestions = data.flatMap(subject => subject.questions);
        setQuestions(allQuestions);
      });
  }, [payload.examTemplateId]);

  const finalsubmitExam = () => {
    fetch('http://localhost:1020/submit-responses', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then(response => {
        const statusCode = response.status;
        return response.json().then(data => ({ data, statusCode }));
      })
      .then(({ data, statusCode }) => {
        console.log('Submission successful:', data);
        console.log('Status code:', statusCode);

        if (statusCode === 200) {
          setIsSubmitted(true);
          setResultData(data); // Assuming the backend returns the result data in the response
        } else if (statusCode === 410) {
          alert('Already submitted');
        }
      })
      .catch(error => {
        console.error('Error submitting responses:', error);
      });
  };

  const handleNoClick = () => {
    navigate(`/template-questions/${payload.examTemplateId}`, {
      state: previousState
    });
  };

  return (
    <div className="exam-summary-container">
      <header className="exam-summary-header">
        <div className="header-content">
          <div className="candidate-info">
            <div>Candidate Name: [Your Name]</div>
            <div>Exam Name: NEET</div>
            <div>Subject Name: PHYSICS</div>
            <div>Remaining Time: <span className="timer">02:56:37</span></div>
          </div>
        </div>
      </header>
      <main>
        {isSubmitted ? (
          <>
            <h2>Submission Successful!</h2>
            <h3>Results</h3>
            {payload.responses && questions.length > 0 && (
              <div className="results-table">
                {payload.responses.map((response, index) => {
                  const question = questions.find(q => q.id === parseInt(response.questionId));
                  if (!question) return null;
                  return (
                    <div key={response.questionId} className="results-row">
                      <h2 className='results-row-heading'>Your Result</h2>
                      <div><strong>Question {index + 1}:</strong> <span dangerouslySetInnerHTML={{ __html: question.pre_question_text }} /></div>
                      <div><strong>Selected Option:</strong> {response.selectedOption}</div>
                      <div><strong>Time Taken:</strong> {Math.round((payload.questionTimes[response.questionId] || 0) / 1000)} seconds</div>
                      <div>
                        <strong>Options:</strong>
                        <ul>
                          <li>A: <span dangerouslySetInnerHTML={{ __html: question.option1_text }} /></li>
                          <li>B: <span dangerouslySetInnerHTML={{ __html: question.option2_text }} /></li>
                          <li>C: <span dangerouslySetInnerHTML={{ __html: question.option3_text }} /></li>
                          <li>D: <span dangerouslySetInnerHTML={{ __html: question.option4_text }} /></li>
                        </ul>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </>
        ) : (
          <>
            <h2>Exam Summary</h2>
            <div className="summary-table">
              <div className="summary-row">
                <div>
                  <div>No of Questions</div>
                  <div className="summary-value">{totalQuestions}</div>
                </div>
                <div>
                  <div>Answered</div>
                  <div className="summary-value">{answered}</div>
                </div>
                <div>
                  <div>Not Answered</div>
                  <div className="summary-value">{notAnswered}</div>
                </div>
                <div>
                  <div>Marked for Review</div>
                  <div className="summary-value">{markedForReview}</div>
                </div>
                <div>
                  <div>Answered & Marked for Review (will be considered for evaluation)</div>
                  <div className="summary-value">{markedForReviewAndAnswered}</div>
                </div>
                <div>
                  <div>Not Visited</div>
                  <div className="summary-value">{notVisited}</div>
                </div>
              </div>
            </div>
            <div className="confirmation">
              <p>
                Are you sure you want to submit for final marking?
                <br />
                No changes will be allowed after submission.
              </p>
              <div className="buttons">
                <button className="yes-button" onClick={finalsubmitExam}>YES</button>
                <button className="no-button" onClick={handleNoClick}>NO</button>
              </div>
            </div>
          </>
        )}
      </main>
      <footer>
        <p>© All Rights Reserved - MITHRA</p>
      </footer>
    </div>
  );
};

export default ExamSummary;
