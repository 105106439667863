import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import QuestionSelectionPage from "./QuestionSelectionPage";

const TemplateDetail = () => {
  const { templateId } = useParams();
  const navigate = useNavigate();
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [templateSubjectId, setTemplateSubjectId] = useState("");
  const [questions, setQuestions] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [requiredNumberOfQuestions, setRequiredNumberOfQuestions] = useState(0); // New state variable
  const [selectedYearId, setSelectedYearId] = useState("");
  const [selectedSubjectId, setSelectedSubjectId] = useState("");
  const [selectedChapterId, setSelectedChapterId] = useState("");
  const [selectedTopicId, setSelectedTopicId] = useState("");
  const [selectedExamId, setSelectedExamId] = useState("");

  useEffect(() => {
    // Fetch subjects for the specific template
    axios
      .get(`http://localhost:1020/exam-template-subjects/${templateId}`)
      .then((response) => {
        setSubjects(response.data);
      })
      .catch((error) => {
        console.error("Error fetching subjects:", error);
      });
  }, [templateId]);

  useEffect(() => {
    if (
      selectedYearId ||
      selectedSubjectId ||
      selectedChapterId ||
      selectedTopicId ||
      selectedExamId
    ) {
      axios
        .get("http://localhost:1020/questions", {
          params: {
            yearId: selectedYearId,
            subjectId: selectedSubjectId,
            chapterId: selectedChapterId,
            topicId: selectedTopicId,
            examId: selectedExamId,
          },
        })
        .then((response) => {
          setQuestions(response.data);
        })
        .catch((error) => {
          console.error("Error fetching questions:", error);
        });
    }
  }, [
    selectedYearId,
    selectedSubjectId,
    selectedChapterId,
    selectedTopicId,
    selectedExamId,
  ]);

  const handleCheckboxChange = (questionId) => {
    setSelectedQuestions((prevSelected) => {
      if (prevSelected.includes(questionId)) {
        return prevSelected.filter((id) => id !== questionId);
      } else {
        return [...prevSelected, questionId];
      }
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    console.log(selectedQuestions.length, requiredNumberOfQuestions);

    if (selectedQuestions.length !== requiredNumberOfQuestions) {
      alert(`You must select exactly ${requiredNumberOfQuestions} questions. Not less`);
      return;
    }

    try {
      await axios.post("http://localhost:1020/exam-template-questions", {
        templateSubjectId: templateSubjectId,
        examTemplateId: templateId,
        questionIds: selectedQuestions,
      });

      console.log("Questions added to template successfully");

      setSelectedQuestions([]);
      navigate("/created-templates");
    } catch (error) {
      console.error("Error adding questions to template:", error);
    }
  };

  const handleSelect = (
    yearId,
    subjectId,
    templateSubjectId,
    numberOfQuestions
  ) => {
    setTemplateSubjectId(templateSubjectId);
    setSelectedYearId(yearId);
    setSelectedSubjectId(subjectId);
    setRequiredNumberOfQuestions(numberOfQuestions); // Update required number of questions
    setSelectedChapterId(""); // Reset chapter selection
    setSelectedTopicId(""); // Reset topic selection
    setSelectedExamId(""); // Reset exam selection
  };

  return (
    <div>
      <h3>Subjects in Template:</h3>
      {subjects.map((subject) => (
        <div key={subject.id}>
          <p>
            Year: {subject.year_id}, Subject: {subject.subject_id}, Number of
            Questions: {subject.number_of_questions}, Status: {subject.status}
          </p>
          <button
            type="button"
            onClick={() =>
              handleSelect(
                subject.year_id,
                subject.subject_id,
                subject.id,
                subject.number_of_questions
              )
            }
            disabled={subject.status === "completed"}
          >
            Select
          </button>
        </div>
      ))}

      <QuestionSelectionPage
        selectedYearId={selectedYearId}
        selectedSubjectId={selectedSubjectId}
        setSelectedYearId={setSelectedYearId}
        setSelectedSubjectId={setSelectedSubjectId}
        setSelectedChapterId={setSelectedChapterId}
        setSelectedTopicId={setSelectedTopicId}
        setSelectedExamId={setSelectedExamId}
      />

      <form onSubmit={handleSubmit}>
        <h3>Select Questions:</h3>
        {questions.map((question) => (
          <div key={question.id}>
            <input
              type="checkbox"
              id={question.id}
              checked={selectedQuestions.includes(question.id)}
              onChange={() => handleCheckboxChange(question.id)}
            />
            <label htmlFor={question.id}>{question.pre_question_text}</label>
          </div>
        ))}
        <button type="submit">Add Questions to Template</button>
      </form>
    </div>
  );
};

export default TemplateDetail;
