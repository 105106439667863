import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const CreatedTemplates = () => {
  const [templates, setTemplates] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get("http://localhost:1020/created-templates")
      .then((response) => {
        setTemplates(response.data);
      })
      .catch((error) => {
        console.error("Error fetching templates:", error);
      });
  }, []);

  const handleTemplateClick = (templateId) => {
    navigate(`/template/${templateId}`);
  };

  return (
    <div>
      <h2>Created Templates</h2>
      <ul>
        {templates.map((template) => (
          <li
            key={template.id}
            onClick={() => handleTemplateClick(template.id)}
          >
            <h3>{template.name}</h3>
            <p>Description: {template.description}</p>
            <p>Plan: {template.plan}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CreatedTemplates;
